import incomings from './lists/Incomings'
import outgoings from './lists/Outgoings'
import inners from './lists/Inners'
import dirords from './lists/Dirords'
import ords from './lists/Ords'
import actitems from './lists/IncomingActItems'
import inneractitems from './lists/InnerActItems'
import euolstatemets from './lists/EuolStatements'
import govservices from './lists/GovServices'
import protocols from './lists/Protocols'
import protocolsactitems from './lists/ProtocolsActItems'
import contracts from './lists/Contracts'

//#region Criterias
const innerActItemsCriterias = [
    {
        // название свойства в расширенном фильтре
        name: "ExecuterEnterpriseId",
        // тип визуального контрола
        type: 'select',
        // плейсхолдер если не выбрано / не введено  (перекочует в правый верхний угол если выбрано / введено)
        label: "Исполнитель",
        // это нужно если контрол - селект
        items: [],
        // это нужно если контрол - селект с поиском
        filteredItems: [],
        // метод для инициализации items
        init: async (store) => (await store.dispatch('references/getInnerActItemsEnterpriseExecuters')).map(i => ({ id: i.id, Value: i.name })),
        // на панели критериев всегда одинарный выбор, поэтому для адекватной работы нужно указать, как всопринимать значение свойства в расширенном фильтре (одинарное: false, множественное: true)
        isMultipleTarget: false,
    }
];
//#endregion

//#region Extended Filter Object Values (EFOV)
const incomingEFOV = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Рег._номер",
        value: null
    },
    OutNumber: {
        label: "Исходящий_номер",
        value: null
    },
    HigherNumber: {
        label: "Номер_вышестоящего_корреспондента",
        value: null
    },
    Correspondent: {
        label: "Корреспондент",
        value: []
    },
    ExecuterName: {
        label: "Исполнитель",
        value: null
    },
    JuridicalDocType: {
        label: "Тип_документа",
        value: null
    },
    SignerName: {
        label: "Подписал",
        value: null
    },
    RegDateFrom: {
        label: "Дата_регистрации_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_регистрации_по",
        value: null
    },
    Recipients: {
        label: "Получатель",
        value: []
    },
    OutDateFrom: {
        label: "Дата_исходящего_c",
        value: null
    },
    OutDateTo: {
        label: "Дата_исходящего_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    Executers: {
        label: "Исполнитель",
        value: []
    },
    ResolutionAuthor: {
        label: "Автор_резолюции",
        value: []
    },
    HasAnswer: {
        label: "Дан_ответ",
        value: null
    },
    ExecuteStatus: {
        label: "Статус_исполнения",
        value: []
    },
    IsDuplicate: {
        label: "Дубликат",
        value: null
    },
    IsIncomingAnswer: {
        label: "Входящий_ответ",
        value: null
    },
    Counter: {
        label: "Журнал_регистрации",
        value: null
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    }
};
const innerEFOV = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Рег._номер",
        value: null
    },
    RegDateFrom: {
        label: "Дата_регистрации_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_регистрации_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    Executers: {
        label: "Исполнитель",
        value: []
    },
    ResolutionAuthor: {
        label: "Автор_резолюции",
        value: []
    },
    Recipients: {
        label: "Получатель",
        value: []
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    },
    JuridicalDocType: {
        label: "Тип_документа",
        value: null
    },
    SignerEmployeeIds: {
        label: "Подписал",
        value: []
    }
};
const protocolEFOV = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Рег._номер",
        value: null
    },
    Correspondent: {
        label: "Корреспондент",
        value: []
    },
    RegDateFrom: {
        label: "Дата_регистрации_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_регистрации_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    Executers: {
        label: "Исполнитель",
        value: []
    },
    ResolutionAuthor: {
        label: "Автор_резолюции",
        value: []
    },
    HasAnswer: {
        label: "Дан_ответ",
        value: null
    },
    Counter: {
        label: "Журнал_регистрации",
        value: null
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    }
};
const innerActItemsEFOV = {
    EFResolutionText: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Рег._номер",
        value: null
    },
    RegDateFrom: {
        label: "Дата_регистрации_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_регистрации_по",
        value: null
    },
    ResolutionAuthor: {
        label: "Автор",
        value: []
    },
    ExecuterWorkStatus:  {
        label: "Статус",
        value: null
    },
    ExecuterEnterpriseId: {
        label: "Исполнитель",
        value: null
    }
};
const outgoingEFOV = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Исходящий_номер",
        value: null
    },
    RegDateFrom: {
        label: "Дата_исходящего_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_исходящего_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    },
    JuridicalDocType: {
        label: "Тип_документа",
        value: null
    },
    OutgoingExecuters: {
        label: "Исполнитель",
        value: []
    },
    Recipients: {
        label: "Получатель",
        value: []
    },
    SendStatus: {
        label: "Статус_отправки",
        value: null
    },
    SignerEmployeeIds: {
        label: "Подписал",
        value: []
    },
    DocumentAuthor: {
        label: "Кем_создано",
        value: []
    }
};
const statementEFOV = {
    EFAnnotation: {
        label: "Краткое_содержание",
        value: null
    },
    Declarant_IIN_BIN:
    {
        label: "ИИН_БИН",
        value: null
    },
    StQuestion:
    {
        label: "Характер_вопроса",
        value: null
    },
    PerCategory:
    {
        label: "Категория_лица",
        value: null
    },
    StLanguage:
    {
        label: "Язык_обращения",
        value: null
    },
    StForm:
    {
        label: "Форма_обращения",
        value: null
    },
    StType:
    {
        label: "Вид_обращения",
        value: null
    },
    ExecuterWorkplaceId:
    {
        label: "Исполнитель",
        value: null
    },
    ControlDateFrom:
    {
        label: "Срок_исполнения_с",
        value: null
    },
    ControlDateTo:
    {
        label: "Срок_исполнения_по",
        value: null
    },
    EUOL_IsRedirected:
    {
        label: "Перенаправлено",
        value: null
    }
};
const contractEFOV = {
    RegDateFrom: {
        label: "Дата_регистрации_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_регистрации_по",
        value: null
    },
    ValidityFrom: {
        label: "Срок_действия_с",
        value: null
    },
    ValidityTo: {
        label: "Срок_действия_по",
        value: null
    },
    PersonCategory: {
        label: "Категория_лица",
        value: []
    },
};

//#endregion
const getEFOV = function (source, excludeProps = []) {
    let efov = Object.assign({}, source);

    if (excludeProps.length > 0)
        excludeProps.forEach( prop => delete efov[prop] );

    return efov;
};

export default {
    namespaced: true,
    state: () => ({
        collection : "Incomings",
        statuses: [
            { collection: "Incomings", value: "All" },
            { collection: "Outgoings", value: "AllOutgoings" },
            { collection: "Inners", value: "Any" },
            { collection: "Dirords", value: "All" },
            { collection: "Ords", value: "All" },
            { collection: "IncomingActItems", value: "All" },
            { collection: "InnerActItems", value: "Avrora.Objects.Modules.Docflow.DocflowObjects.ActItem" },
            { collection: "EuolStatements", value: "Registered" },
            { collection: "GovServices", value: "Any" },
            { collection: "Contracts", value: "Any" },
            { collection: "Protocols", value: "All" },
            { collection: "ProtocolsActItems", value: "All" }
        ],
        searches: [
            { collection: "Incomings", value: null },
            { collection: "Outgoings", value: null },
            { collection: "Inners", value: null },
            { collection: "Dirords", value: null },
            { collection: "Ords", value: null },
            { collection: "IncomingActItems", value: null },
            { collection: "InnerActItems", value: null },
            { collection: "EuolStatements", value: null },
            { collection: "GovServices", value: null },
            { collection: "Contracts", value: null },
            { collection: "Protocols", value: null },
            { collection: "ProtocolsActItems", value: null }
        ],
        extendedFilters:[
            {
                collection: "Incomings",
                value: getEFOV(incomingEFOV)
            },
            {
                collection: "Dirords",
                value: getEFOV(incomingEFOV)
            },
            {
                collection: "IncomingActItems",
                value: getEFOV(incomingEFOV, [ 'IsIncomingAnswer', 'Counter' ])
            },
            {
                collection: "Outgoings",
                value: getEFOV(outgoingEFOV)
            },
            {
                collection: "Inners",
                value: getEFOV(innerEFOV, ['SignerEmployeeIds'])
            },
            {
                collection: "Ords",
                value: getEFOV(innerEFOV)
            },
            {
                collection: "InnerActItems",
                value: getEFOV(innerActItemsEFOV)
            },
            {
                collection: "EuolStatements",
                value: getEFOV(statementEFOV)
            },
            {
                collection: "GovServices",
                value: getEFOV(statementEFOV, [ 'IsIncomingAnswer', 'Counter' ])
            },
            {
                collection: "Contracts",
                value: getEFOV(contractEFOV)
            },
            {
                collection: "Protocols",
                value: getEFOV(protocolEFOV, ['SignerEmployeeIds'])
            },
            { 
                collection: "ProtocolsActItems", 
                value: getEFOV(protocolEFOV, ['SignerEmployeeIds'])
            }
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        },
        PUSH_EXTENDED_FILTER (state, payload) {
            let exists = state.extendedFilters.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.extendedFilters.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.statuses.find(x => x.collection === state.collection).value;
        },
        getSearch (state) {
            return state.searches.find(x => x.collection === state.collection).value;
        },
        getExtendedFilter (state, getters) {
            if (state.collection === 'EuolStatements' && getters.getStatus === 'EuolAnswer')
                return state.extendedFilters.find(x => x.collection === 'Outgoings')?.value ?? {};

            return state.extendedFilters.find(x => x.collection === state.collection)?.value ?? {};
        },
        getCriterias: (s, getters) => {
            switch (s.collection)
            {
                case 'InnerActItems':
                    return getters.getStatus === "Avrora.Objects.Modules.Docflow.DocflowObjects.ActItem" ? innerActItemsCriterias : [] ;

                default:
                    return []
            }
        }
    },
    actions: {
        getExtendedFilterByCollection ({ state }, collection) {
            return state.extendedFilters.find(x => x.collection === collection).value;
        },
        resetOutgoingFilter({ state }) {
            state.extendedFilters.find(x => x.collection === "Outgoings").value = getEFOV(outgoingEFOV);
        }
    },
    modules: {
        incomings,
        outgoings,
        inners,
        dirords,
        ords,
        actitems,
        inneractitems,
        euolstatemets,
        govservices,
        protocols,
        protocolsactitems,
        contracts
    }
}