export default {
    namespaced: true,
    state: () => ({
        currentPeriod: 'day', // day, week, month
        warningShowed: false
    }),
    mutations: {
        SET_CURRENT_PERIOD (state, payload) {
            state.currentPeriod = payload;
        },
        SET_WARNING_SHOWED(state, payload){
            state.warningShowed = payload;
        }
    },
    getters: {
        getCurrentPeriod: (s) => s.currentPeriod,
        getWarningShowed: (s) => s.warningShowed
    }
}