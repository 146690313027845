<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t('Тип_контакта')}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-select
                                        v-model="contact.type"
                                        :items="contacttypes"
                                        item-text="name" 
                                        item-value="id"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t('Контакт')}}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="contact.value"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t('Описание')}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-textarea 
                                        v-model="contact.description"
                                        rows="3"
                                        no-resize
                                        hide-details
                                        required
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        v-if="contact.value"
                        color="cyan" 
                        text 
                        depressed 
                        @click="save" 
                        >
                        {{$t("Сохранить")}}
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: "ContactsDlg",
    data () {
        return {
            title: i18n.t("Информация_о_контакте"),
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            contact:{
                id:'',
                type: 'phone',
                value: '',
                description:'',
                isNew: true
            },
            contacttypes:[
                { id: 'email', name: i18n.t('Электронная_почта') },
                { id: 'phone', name: i18n.t('Телефон') }
            ]
        }
    },
    methods: {
        async open(value)
        {
            if (value != null) {
                this.contact.id = value.id;
                this.contact.type = value.type;
                this.contact.value = value.value;
                this.contact.description = value.description;
                this.contact.isNew = false;
            }
            else {
                this.contact = {
                    id: window.crypto.getRandomValues(new Uint16Array(1))[0].toString(),
                    type: 'phone',
                    value: '',
                    description:'',
                    isNew: true
                 }; 
            }
            this.visible = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        save() {
            this.visible = false;
            this.$emit('on-save-contact', this.contact);
        },
        cancel() {
            this.visible = false;
        }
    }
}
</script>