const setSign = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,

        documentType: null,

        decision: null,
        decisions: [
            { code: 1, value: "Подписать" },
            { code: 2, value: "Вернуть_на_доработку" },
            { code: 3, value: "Отказать" }
        ],
        signType: null,
        signTypes: [
            { code: 1, value: "УЦГО" },
            { code: 2, value: "НУЦ" },
            { code: 3, value: "Простая_подпись" }
        ],
        serverSignType: null,

        comment: null,
        attachment: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_DECISION(state, payload) {
            state.decision = payload;
        },
        SET_SIGN_TYPE(state, payload) {
            state.signType = payload;
        },
        SET_SERVER_SIGN_TYPE(state, payload) {
            state.serverSignType = payload;
        },
        SET_COMMENT(state, payload) {
            state.comment = payload;
        },
        SET_ATTACHMENT(state, payload) {
            state.attachment = payload;
        },
        SET_DOCUMENT_TYPE(state, payload) {
            state.documentType = payload;
        }
    },
    actions: {
        async open({ commit, dispatch }, type) {
            commit('SET_DECISION', null);
            commit('SET_SIGN_TYPE', null);
            commit('SET_COMMENT', null);
            commit('SET_ATTACHMENT', null);

            let serverSignType = await dispatch('signing/getUsedSignType', null, { root: true });
            commit('SET_SERVER_SIGN_TYPE', serverSignType);

            commit('SET_VISIBLE', true);
            commit('SET_DOCUMENT_TYPE', type);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        changeDecision({ commit }, v) {
            commit('SET_SIGN_TYPE', null);

            if (v === 1) {
                commit('SET_COMMENT', null);
                commit('SET_ATTACHMENT', null);
            }

            if (v === 3) {
                commit('SET_ATTACHMENT', null);
            }

            commit('SET_DECISION', v);
        },
        async ok({ commit, state }, redirect) {
            commit('SET_VISIBLE', false);

            let result =  {
                resultCode: state.decision,
                signType: state.signType,
                comment: state.comment,
                attachment: state.attachment,
                redirect
            };

            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        getDecisions: (s) => s.decisions,
        getDecision: (s) => s.decision,
        getSignType: (s) => s.signType,
        getComment: (s) => s.comment,
        getAttachment: (s) => s.attachment,
        getSignTypes: (s) => {
            let types = [ s.serverSignType ];

            if (s.documentType === 'InnerDocument')
                types.push(3);

            return s.signTypes.filter(x => types.includes(x.code));
        }
    }
}

export default setSign;