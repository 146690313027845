import mycreated from "./lists/mycreated";
import myexecution from "./lists/myexecution";

const reportsgeneration = {
    namespaced: true,
    state: () => ({
        collection : "AllMyReport",
        statuses: [
            {
                collection: "AllMyReport",
                value: 'All'
            },
            {
                collection: "MyCreated",
                value: 'All'
            },
            {
                collection: "MyExecution",
                value: 'All'
            }
        ],
        searches: [
            {
                collection: "AllMyReport",
                value: null
            },
            {
                collection: "MyCreated",
                value: null
            },
            {
                collection: "MyExecution",
                value: null
            }
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let searchExists = state.searches.find(x => x.collection == payload.collection);

            if (searchExists) {
                searchExists.value = payload.value;
            } else {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection: (s) => s.collection,
        getSearch: (s) => s.searches.find(x => x.collection === s.collection)?.value,
        getStatus: (s) => s.statuses.find(x => x.collection === s.collection)?.value,
    },
    modules: {
        mycreated,
        myexecution,
    }
};

export default reportsgeneration;