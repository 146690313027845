<template>
    <div>
        <v-dialog 
            v-model="visible" 
            transition="dialog-top-transition" 
            scrollable 
            persistent 
        >
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    
                    <input id="ownPhotoUpload" ref="imageUploader" v-on:change="onFileChange" type="file" accept=".jpg,.png,.gif,.bmp,.svg,.tif" hidden>
                    <v-form ref="form" lazy-validation>
                        <v-row dense>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="section-wrapper">
                                <v-card class="user-page-main-info-grid colorWdj" flat>
                                    <v-card-text>
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-row no-gutters>
                                                    <v-col  cols="12" sm="12" md="12">
                                                        <div class="onlyReadData upmi-fio">{{getUserInfo.userName.replace(/ *\([^)]*\) */g, "")}}</div>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col  cols="12" sm="12" md="12">
                                                        <div class="onlyReadData">
                                                            <label class="f-label">{{jobTitle}}</label>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col  cols="12" sm="12" md="12">
                                                        <div class="onlyReadData">
                                                            <label class="f-label">{{getUserInfo.enterpriseName}}</label>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col md="auto" class="d-flex justify-end">
                                                <div class="p-relative d-flex align-center">
                                                    <v-icon 
                                                        v-if="!ownProfile || ownProfile.photo.length == 0"
                                                        class="user-page-main-info-nophoto"
                                                    >
                                                        fas fa-user-circle
                                                    </v-icon>
                                                    <v-img
                                                        v-else
                                                        :src="ownProfile.photo"
                                                        class="user-page-main-info-photo"
                                                    >
                                                    </v-img>
                                                    <div class="upmi-actions">
                                                        <div
                                                            v-if="!ownProfile || ownProfile.photo.length == 0"
                                                            v-tooltip.top-center="$t('Добавить_фото')"
                                                            @click="chooseFiles"
                                                        >
                                                            <v-icon>fas fa-edit</v-icon>
                                                        </div> 
                                                        <div
                                                            v-else
                                                            v-tooltip.top-center="$t('Удалить_фото')"
                                                            @click="deletePhoto"
                                                        >
                                                            <v-icon>fas fa-trash</v-icon>
                                                        </div>    
                                                    </div>
                                                </div>
                                            </v-col>   
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row dense>    
                            
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Основное")}}</div>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Дата_рождения")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <!--<div class="onlyReadData">12.03.1990</div>-->
                                                <v-menu 
                                                    v-model="hbDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="birthDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                            clearable
                                                            @click:clear="ownProfile.birth_date = null"
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="birthDate"
                                                        @input="hbDateMenu = false"
                                                        color="teal"
                                                        :first-day-of-week="1"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-card-text>
                                </v-card>

                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Интерфейс")}}</div>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Язык_интерфейса") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-select
                                                    return-object
                                                    :items="locales"
                                                    :value="locale"
                                                    @input="onLocaleInput"
                                                    item-text="value"
                                                    item-value="id"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Число_записей_на_страницу") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-autocomplete
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :items="defaultPaginationOptions"
                                                    v-model="defaultPagination"
                                                    disabled
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Включить_уведомления_в_браузере")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-switch
                                                    v-model="browserNotifications"
                                                    inset
                                                    hide-details
                                                    class="cust-switch"
                                                    disabled
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Предпросмотр_вложений_по_умолчанию")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-switch
                                                    inset
                                                    hide-details
                                                    class="cust-switch"
                                                    disabled
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                            </v-col>

                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <v-card flat v-if="appConfiguration != 'C5'">
                                    <v-card-text>
                                        <div class="form-box-title title-with-right-part">
                                            <span>{{$t("Публичный_ключ")}}</span>
                                            <div class="twrp-content">
                                                <v-chip
                                                    v-if="!certData" 
                                                    class="table-label"
                                                    small
                                                    label
                                                    color="#009c95"
                                                    dark
                                                    @click="ImportCertificate"
                                                >
                                                    <div class="d-flex align-center">
                                                        <v-icon>fas fa-key</v-icon>
                                                        {{$t("Загрузить_ключ")}}
                                                    </div>
                                                </v-chip>
                                                <v-chip
                                                    v-else 
                                                    class="table-label"
                                                    small
                                                    label
                                                    color="#009c95"
                                                    dark
                                                    @click="removeCertificate"
                                                >
                                                    <div class="d-flex align-center">
                                                        <v-icon>fas fa-undo</v-icon>
                                                        {{$t("Отвязать_ключ")}}
                                                    </div>
                                                </v-chip>
                                            </div>
                                        </div>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Тип_удостоверяющего_центра")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{certIssuer}}</div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Владелец")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{nameFromDN}}</div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters v-if="certData && certData.Algorithm.startsWith('ECGOST')">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Организация")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{enterpriseFromDN}}</div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Дата_выдачи_сертификата/ключа")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{certNotBefore}}</div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Сертификат/ключ_действителен_до")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{certNotAfter}}</div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <v-card flat v-if="ownSubs!=null && ownSubs.length>0">
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Замещение")}}</div>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ownSubs[0].status=='Substantial' ? $t("Замещающий") : $t("Замещаемый")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-workplace-chip
                                                    :id="ownSubs[0].workplaceid"
                                                    :name="ownSubs[0].fio"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("С_даты")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ownSubs[0].fromdate}}</div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("По_дату")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ownSubs[0].todate}}</div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>                
                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="section-wrapper">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Контакты") }}</div>
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{ $t("Контакты") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="10">
                                                <div class="onlyReadData more-per-lab-wrap">
                                                    <template v-if="ownProfile && ownProfile.person_contacts.length>0">
                                                        <v-contact-item
                                                            v-for="contact in ownProfile.person_contacts"
                                                            :key="contact.id"
                                                            :type="contact.type"
                                                            :icon="contact.icon"
                                                            :title="contact.value"
                                                            :description="contact.description"
                                                            :hide-load="true"
                                                            :is-read-only="false"
                                                            @editcontact="editContact" 
                                                            @deletecontact="deleteContact"
                                                        />
                                                    </template>
                                                    <template>
                                                        <v-chip
                                                            class="person-label immutable-text"
                                                            small
                                                            label
                                                            outlined
                                                            @click="onAddContact()"
                                                        >
                                                            <i class="fas fa-plus dark-gray-color"></i>
                                                            <div>{{ $t("Добавить_контакт") }}</div>
                                                        </v-chip>
                                                    </template>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="profileChanged" 
                        color="cyan" 
                        text
                        depressed
                        @click="onSaveProfileBtnClick"
                    >
                        {{$t("Сохранить")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
        <ContactsDlg ref="ContactsRef" @on-save-contact="saveContact" />
        <ImportCertificateDlg />   
    </div>
</template>

<script>
import i18n from '@/i18n';
import sys from '@/services/system';
import { httpAPI } from '@/api/httpAPI';
import { mapGetters, mapActions } from 'vuex';
import ContactsDlg from '@/components/dialogs/ContactsDlg';
import ImportCertificateDlg from '@/components/dialogs/ImportCertificateDlg';
import CryptoJS from 'crypto-js';

export default {
    name: "ProfileDlg",
    components: {
        ContactsDlg,
        ImportCertificateDlg
    },
    data () {
        return {
            title: "Профиль",
            visible: false,
            resolve: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            source: null,
            locales: [ { id: 'kk', value: 'қазақ' }, { id: 'ru', value: 'русский' } ],
            defaultPaginationOptions: [ 5, 10, 20 ],
            browserNotifications: true,
            hbDateMenu: false,
            ownProfile: null,
            initIni:'',
            initBirthDate:'',
            contactsChanged: false,
            certData: null,
            certprofile:'',
            ownSubs: null,
            //строки ниже заглушка, потом удалить
            documentHbDate: '',
        }
    },
    computed: {
        ...mapGetters('auth',['getUserInfo', 'getUserEDSNUCStatus']),
        ...mapGetters({ iinRule: 'getIinRule'}),
        profileChanged(){
            if (!this.ownProfile)
                return false;
            return this.initIni != this.ownProfile.person_code ||
                this.initBirthDate != this.ownProfile.birth_date ||
                this.ownProfile.photochanged ||
                this.contactsChanged;
        },
        nameFromDN() {
            if (this.certData)
                return sys.getNameFromDN(this.certData.Subject);

            return "";
        },
        enterpriseFromDN() {
            if (this.certData && this.certData.Algorithm.startsWith('ECGOST'))
                return sys.getValueFromDN(this.certData.Subject, 'O');
            else
                return "";
        },
        certIssuer(){
            let name = '';
            if (this.certData){
                let tokens = this.certData.Issuer.split(',');
                let CNToken = tokens.find( x => x.startsWith('CN='));

                name = CNToken.replace("CN=", "");
            }
            return name;
        },
        jobTitle() {
            return this.getUserInfo.userName.match(/\((.*)\)/)?.pop() ?? "";
        },
        birthDate: {
            get: function() {
                return this.ownProfile && this.ownProfile.birth_date ? this.$moment(this.ownProfile.birth_date).format('YYYY-MM-DD') : null;
            },
            set: function(newValue) {
                if (this.ownProfile)
                    this.ownProfile.birth_date = `/Date(${Date.parse(newValue)})/`;
            }
        },
        certNotBefore() {
            return this.certData && this.certData.NotBefore ? this.$moment(this.certData.NotBefore).format('DD.MM.YYYY HH:mm:ss') : '';
        },
        certNotAfter() {
            return this.certData && this.certData.NotAfter ? this.$moment(this.certData.NotAfter).format('DD.MM.YYYY HH:mm:ss') : '';
        },
        iin: {
            get: function() {
                return this.ownProfile ? this.ownProfile.person_code : null;
            },
            set: function(newValue) {
                if (this.ownProfile)
                    this.ownProfile.person_code = newValue;
            }
        },
        locale() {
            return i18n.locale;
        },
        defaultPagination: {
            get: function() {
                return this.$store.getters['getDefaultPagination'];
            },
            set: function(v) {
                this.$store.commit('SET_DEFAULT_PAGINATION', v);
            }
        },
        appConfiguration(){
            return process.env.VUE_APP_CONFIGURATION;
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    methods: {
        async open(source)
        {
            this.initialize();
            this.source = source;
            this.visible = true;
                    
            return new Promise((resolve) => {
                this.resolve = resolve;
            });
            
        },
        cancel() {
            this.visible = false;
            this.ownProfile = null;
            this.resolve();
        },
        ...mapActions('dialogs/importCertificate', { openImportCertificateDlg: 'open' }),
        ...mapActions('signing', ['getUsedSignType']),
        async getProfile(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let url = `/api/ems/getownprofile`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            
            if (response) {
                this.certData = response.data.payload.certificate;
                response.data.payload.certificate = null;//раз он не в общем сохранении будет то и нечего его гонять  лишний раз
                this.ownProfile = response.data.payload;

                this.fixProfileInitState();
            }
        },
        async getSubstatail() {
            let url = `/api/ems/getownsubstatail`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (response) {
                this.ownSubs = response.data.payload;
            }
        },
        async saveProfile(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let url=`/api/ems/saveownprofile`;
            let senddata = { Content : JSON.stringify(this.ownProfile) };

            let response = await httpAPI({
                url: url,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: senddata,
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            return response;
        },
        async onSaveProfileBtnClick() {
            
            let response = await this.saveProfile();

            if (response) {
                this.$notify.success(response.data.payload.Message);
                this.fixProfileInitState();
                this.$eventBus.$emit('update_profile');
            }
          
        },
        fixProfileInitState(){
            this.initIni = this.ownProfile.person_code;
            this.initBirthDate = this.ownProfile.birth_date;
            this.contactsChanged = false;
            this.ownProfile.photochanged = false;
        },
        async initialize(){
            await this.getProfile();
            await this.getSubstatail();
        },
        async onLocaleInput(locale) {
            this.$store.commit('SET_LANGUAGE_CHANGING', true);

            await httpAPI({
                url: `/api/actions/changeuserlanguage`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content: locale.id },
                skipErrorHandler: true
            });
            
            localStorage.setItem('AppLocale', locale.id)
            i18n.locale = locale.id;
            this.$vuetify.lang.current = locale.id;

            this.$store.commit('SET_LANGUAGE_CHANGING', false);
        },
        //#region Работа с контактами
        async openContactEditDlg(contact) {
            try
            {
                await this.$refs.ContactsRef.open(contact);
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async onAddContact() {
            await this.openContactEditDlg(null);
        },        
        saveContact(contact) {            
            if (this.ownProfile.person_contacts == null)
                this.ownProfile.person_contacts = [];

            if (contact.isNew) {
                this.ownProfile.person_contacts.push(contact);
            }
            else {
                let existedContact = this.ownProfile.person_contacts.find(x => x.id === contact.id);

                if (existedContact) {
                    existedContact.value = contact.value;
                    existedContact.type = contact.type;
                    existedContact.description = contact.description;
                }
            }
            this.contactsChanged = true;
        },
        editContact(contactId) {
            let existedContact = this.ownProfile.person_contacts.find(x => x.id === contactId);

            if (existedContact)
                this.openContactEditDlg(existedContact)
        },
        deleteContact(contactId) {
            let existedContact = this.ownProfile.person_contacts.find(x => x.id === contactId);

            if (existedContact){
                this.ownProfile.person_contacts.splice(this.ownProfile.person_contacts.indexOf(existedContact), 1);
                this.contactsChanged = true;
            }
        },
        //#endregion

        //#region Работа с фото
        chooseFiles: function() {
            document.getElementById("ownPhotoUpload").click();
        },
        deletePhoto(){
            this.ownProfile.photo = '';
            this.ownProfile.photochanged = true;
            this.$refs.imageUploader.value = '';
        },
        onFileChange(e){
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;
                
            var reader = new FileReader();
            reader.readAsDataURL(files[0])
            reader.onload = () => {
                this.ownProfile.photo = reader.result;
                this.ownProfile.photochanged = true;
            };
        },
        //#endregion
        
        async ImportCertificate(){
            let usedSignType = await this.getUsedSignType();
            switch (usedSignType){
                case 1:
                default:
                    this.ImportCertificateUGSO();
                    break;
                case 2:
                    this.importCertificateNIC();
                    break;
            }
        },
        async ImportCertificateUGSO() {
            try
            {
                let password = process?.env?.VUE_APP_TUMAR_PROMPT_PASSWORD === "true"
                    ? await this.$store.dispatch('signing/promptPassword')
                    : await this.$store.dispatch('signing/getSHA256Secret');

                let cert = await this.openImportCertificateDlg({ password });
                let from = `/Date(${Date.parse(this.$moment(cert.certificate.NotBefore, 'DD.MM.YYYY HH:mm:ss').toDate())})/`;
                let fromdate = this.$moment(from);
                let to = `/Date(${Date.parse(this.$moment(cert.certificate.NotAfter, 'DD.MM.YYYY HH:mm:ss').toDate())})/`;
                let todate =this.$moment(to);
                let today = new Date();
                let errors = [];
                if (fromdate > today || todate < today)
                    errors.push(i18n.t('Неверный_срок_действия_сертификата'))
                if (errors.length > 0){
                    this.$notify.alert(errors.join('\r\n'));
                    return;
                }

                let certOnSave = {
                    Subject: cert.dn, //Может быть такое что в RawData будет другой DN?
                    Issuer: cert.certificate.Issuer,
                    SerialNumber: cert.certificate.SerialNumber,
                    Algorithm: cert.certificate.Algorithm,
                    NotBefore: from, 
                    NotAfter: to, 
                    RawData: cert.certificateRaw
                };
                this.certprofile = cert.profile;

                await this.SaveCertificate(certOnSave); // Прям сразу сохранять без запроса?? а если по ошибке чужой импортнули?
            }
            catch (ex)
            {
                this.$notify.alert(ex.message);
            }
        },
        async SaveCertificate(cert){
            if (cert){
                let url=`/api/ems/setcertificate`;
                let senddata = { Content : JSON.stringify({certificate: cert, profile: this.certprofile})};

                let response = await httpAPI({
                    url: url,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: senddata,
                });
                if (response) {
                    this.certData = cert;
                    this.$notify.success(response.data.payload.Message);
                }
            }
        },
        async importCertificateNIC() {
            try
            {
                let { keyInfo } = await this.$store.dispatch('signing/getNICProfiles');

                if (!keyInfo)
                    return;
                let from = `/Date(${keyInfo.certNotBefore})/`;
                let to = `/Date(${keyInfo.certNotAfter})/`;
                let fromdate = this.$moment(from);
                let todate =this.$moment(to);
                let today = new Date();
                let errors = [];
                if (fromdate > today || todate < today)
                    errors.push(i18n.t('Неверный_срок_действия_сертификата'))
                if (!keyInfo.algorithm.startsWith('ECGOST'))
                    errors.push(i18n.t('Неверный_тип_сертификата'))
                if (errors.length > 0){
                    this.$notify.alert(errors.join('\r\n'));
                    return;
                }
                let certOnSave = {
                    Subject: keyInfo.subjectDn, 
                    Hash: this.grabAndHash(keyInfo.subjectDn),
                    Issuer: keyInfo.issuerDn,
                    SerialNumber: keyInfo.serialNumber,
                    Algorithm: keyInfo.algorithm,
                    NotBefore: from , 
                    NotAfter: to, 
                    RawData: keyInfo.pem
                };
                this.certprofile = '';
                await this.SaveCertificate(certOnSave);
            }
            catch(ex)
            {
                this.$notify.alert(ex.message);
            }
        },
        async removeCertificate() {
            let response = await httpAPI({
                url: `/api/ems/clearcertificate`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });

            if (response){
                this.certData = null;
                this.$notify.success(response.data.payload.Message);
            }
        },
        grabAndHash(source) {
            if (!source)
                return null;

            let anchor = "SERIALNUMBER=";
            let startIndex = source.indexOf(anchor);

            if (startIndex < 0)
                return null;

            let seed = source.substring(startIndex + anchor.length + 3, startIndex + anchor.length + 15);
            return CryptoJS.SHA256(seed).toString(CryptoJS.enc.Hex);
        }
    },
    created() {
        (async() => {
            
        })();      
    },
}
</script>