<template>
    <div>
        <router-view />
        <UserReplacePassword ref="UserReplacePasswordRef" />
        <PromptProfileDlg v-if="isPromptProfileVisible" />
    </div>
</template>

<script>
import UserReplacePassword from '@/components/dialogs/UserReplacePassword';
import { mapGetters } from 'vuex';

export default {
    name: "Logo",
    components:{
        UserReplacePassword,
        PromptProfileDlg: () => import('@/components/dialogs/signing/PromptProfileDlg'),
    },
    computed: {
        ...mapGetters('signing', [ 'isPromptPasswordVisible', 'isPromptProfileVisible' ]),
    }
}
</script>