import { httpAPI } from "@/api/httpAPI";

const cscRegistry = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,
        
        reserveItem: null,
        counterType: 1,
        customNumber: '',
        registerParam: {},
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_COUNTER_TYPE(state, payload) {
            state.counterType = payload;            
        },
        SET_REGISTER_PARAM(state, payload) {
            state.registerParam = payload;
        },
        SET_CUSTOM_NUMBER(state, payload){
            state.customNumber = payload;
        }
    },
    actions: {
        async open({ commit, dispatch, rootGetters }) 
        {
            try
            {
                dispatch('setOverlayVisible', { visible: true }, { root: true });
                
                let documentId = rootGetters['actionsource/getDataSource'].Data.Object.Document.id;
                
                let response = await httpAPI({
                    url: '/api/csc/requests/registerparam',
                    method: 'POST',
                    data: { Content : JSON.stringify({ id: documentId, param: { PrevYear: false }}) },
                    headers: { 'Content-Type': 'application/json' }
                });
                
                commit('SET_COUNTER_TYPE', 1);
                commit('SET_REGISTER_PARAM', response.data.payload.Data.Object);
                commit('SET_CUSTOM_NUMBER', "");

            }
            catch (exception)
            {
                console.error(exception.message);
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }

            commit('SET_VISIBLE', true);
            
            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ state, commit, dispatch, getters, rootGetters }) 
        {
            commit('SET_VISIBLE', false);

            try
            {
                dispatch('setOverlayVisible', { visible: true, text: `Регистрация...` }, { root: true });
                let regParam = JSON.parse(JSON.stringify(getters.getRegisterParam));

                if (state.counterType == 3){
                    regParam.FromReserv = false;
                    regParam.FromCustom = true;
                    regParam.OtherNumber = getters.getCustomNumber;
                }

                let documentId = rootGetters['actionsource/getDataSource'].Data.Object.Document.id;

                let response = await httpAPI({
                    url: '/api/csc/requests/register',
                    method: 'POST',
                    data: { Content : JSON.stringify({ id: documentId, param:  regParam }) },
                    headers: { 'Content-Type': 'application/json' }
                });
                
                state.resolve(response.data.payload);
            }
            catch (exception)
            {   
                console.error(exception.message);
                state.reject();
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ isCancelled: true });
        },
        setReserveItem({ commit }, v) {
            commit('SET_RESERVE_ITEM', v);
        },
    },
    getters: {
        getVisible: (s) => s.visible,
        getRegisterParam: (s) => s.registerParam,
        getIsFormValid: (s) => s.registerParam?.SelectedCounter != null && ((s.counterType == 1 && s.registerParam.PreparedNumber) || (s.counterType == 3 && s.customNumber)),
        getCounterType: (s) => s.counterType,
        getCustomNumber: (s) => s.customNumber,
    }
}

export default cscRegistry;