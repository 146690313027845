import contracts from './lists/contracts'

const contractEFOV = {
    RegDateFrom: {
        label: "Дата_регистрации_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_регистрации_по",
        value: null
    },
    ValidityFrom: {
        label: "Срок_действия_с",
        value: null
    },
    ValidityTo: {
        label: "Срок_действия_по",
        value: null
    },
    PersonCategory: {
        label: "Категория_лица",
        value: []
    },
};

export default {
    namespaced: true,
    state: () => ({
        collection : "Contracts",
        statuses: [
            { collection: "Contracts", value: "Any" },
        ],
        searches: [
            { collection: "Contracts", value: null },
        ],
        extendedFilters: [
            {
                collection: "Contracts",
                value: contractEFOV
            }
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.searches.push(payload);
            }
        },
        PUSH_EXTENDED_FILTER (state, payload) {
            let exists = state.extendedFilters.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.extendedFilters.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.statuses.find(x => x.collection === state.collection)?.value ?? null;
        },
        getSearch (state) {
            return state.searches.find(x => x.collection === state.collection)?.value ?? null;
        },
        getExtendedFilter (state) {
            return state.extendedFilters.find(x => x.collection === state.collection)?.value ?? {};
        },
    },
    modules: {
        contracts
    }
}