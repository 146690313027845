//import sys from '@/services/system';

const cscFormAnAnswer060X0001 = {
    namespaced: true,
    state: {
        isPending: false,
        isVisible: false,

        resolve: null,
        reject: null,

        contractDate: null
    },
    mutations: {
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_IS_VISIBLE(state, payload) {
            state.isVisible = payload;
        },

        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },

        SET_CONTRACT_DATE(state, payload) {
            state.contractDate = payload;
        },
      
    },
    actions: {
        async open({ commit }) {

            commit('SET_IS_PENDING', true);
            commit('SET_IS_VISIBLE', true);
            
            //Initialize
            commit('SET_CONTRACT_DATE', null);


            commit('SET_IS_PENDING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state, rootGetters }) {
            commit('SET_IS_VISIBLE', false);

            let result =  {
                DocumentId: rootGetters['actionsource/getDataSource'].Data.Object.Document.id,
                ContractDate: state.contractDate,
            };       


            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_IS_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        setDecision({ commit }, v) {
            commit('SET_DECISION', v);
        },
    },
    getters: {
        isPending: (s) => s.isPending,
        isVisible: (s) => s.isVisible,        
        isValid: (s) => !!s.contractDate,
        contractDate: (s) => s.contractDate, 
    }
}

export default cscFormAnAnswer060X0001;