import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import sys from '@/services/system';

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: { layout: 'login-layout', config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => process.env.VUE_APP_CONFIGURATION == 'C4' ? import('@/views/LoginNoGts.vue') : import('@/views/LoginFullARM.vue'),
    },
    {
        path: '/',
        name: 'Home',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/projects',
        name: 'Projects',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Projects/List.vue'),
    },
    {
        path: '/requests',
        name: 'Requests',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C5 ] },
        component: () => import('@/views/IQala/List.vue'),
    },
    {
        path: '/contracts',
        name: 'Contracts',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C5 ] },
        component: () => import('@/views/Contracts/Proxy.vue'),
    },
    {
        path: '/inwork',
        name: 'InWorkList',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ]},
        component: () => import('@/views/InWork/Proxy.vue'),
    },
    {
        path: '/handled',
        name: 'HandledList',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ]},
        component: () => import('@/views/Handled/Proxy.vue'),
    },
    {
        path: '/chancellary',
        name: 'Chancellary',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Chancellary/Proxy.vue'),
    },
    {
        path: '/correspondence',
        name: 'Correspondence',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Correspondence/Proxy.vue'),
    },
    {
        path: '/events-management',
        name: 'EventsManagement',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/EventsManagment/Proxy.vue'),
    },
    {
        path: '/archive',
        name: 'Archive',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Archive/Proxy.vue'),
    },
    {
        path: '/executioncontrol',
        name: 'ExecutionControl',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/ExecutionControl/Proxy.vue'),
    },
    {
        path: '/tasks',
        name: 'Tasks',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => import('@/views/Tasks/Proxy.vue'),
    },    
    {
        path: '/reports',
        name: 'Reports',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Reports/Reports.vue'),
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Settings/Proxy.vue'),
    },
    {
        path: '/dsp',
        name: 'DSP',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/DocumentsDSP/Proxy.vue'),
    },    
    {
        path: '/npa',
        name: 'NPA',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/NPA/List.vue'),
    },
    {
        path: '/sandbox',
        name: 'Sandbox',
        meta: { layout: 'main-layout', authRequired: true, deleteDocumentOpenStatus: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => import('@/views/Sandbox.vue'),
        beforeEnter: (to, from, next) =>  {
            if (Vue.config.devtools) {
                next();
            }
            else {
                console.error("Этот модуль недоступен в рабочем окружении!");
            }
        }
    },
    {
        path: '/requests/request',
        name: 'IQalaStatement',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C5 ] },
        component: () => import('@/views/IQala/Card.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/cscorder',
        name: 'IQalaOrder',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C5 ] },
        component: () => import('@/views/IQala/IQalaOrderCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/contract',
        name: 'Contract',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C5 ] },
        component: () => import('@/views/Documents/ContractCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/incoming',
        name: 'IncomingDocument',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Documents/IncomingCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/outgoing',
        name: 'OutgoingDocument',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Documents/OutgoingCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/outgoingcommon',
        name: 'OutgoingDocumentCommon',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Documents/CommonOutgoingCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/inner',
        name: 'InnerDocument',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Documents/InnerCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/euolstatement',
        name: 'EuolStatement',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Documents/EuolStatement.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/resolution',
        name: 'Resolution',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Tasks/Resolution.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/actitem',
        name: 'ActItem',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Tasks/ActItem.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/order',
        name: 'Order',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Tasks/Order.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/counter',
        name: 'Counter',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Settings/Counter.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/euolcounter',
        name: 'EuolCounter',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Settings/EuolCounter.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/nomenclature',
        name: 'Nomenclature',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Settings/Nomenclature.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/enterprise',
        name: 'ContractorEnterprise',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Settings/ContractorEnterprise.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/person',
        name: 'ContractorPerson',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Settings/ContractorPerson.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/npadocument',
        name: 'NPADocument',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Documents/NPADocumentCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/reportsgeneration',
        name: 'ReportsGeneration',
        meta: {layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/ReportsGeneration/List.vue'),
    },
    {
        path: '/reportquery',
        name: 'ReportQuery',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/ReportsGeneration/ReportQuery.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/reportresolution',
        name: 'ReportResolution',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/ReportsGeneration/ReportTask.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/reportinteraction',
        name: 'ReportInteraction',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/ReportsGeneration/ReportInteractionCard.vue'),
        props: (route) => ({ queryId: route.query.queryId, executionId: route.query.executionId, ...route.params })
    },
    {
        path: '/incomingdsp',
        name: 'IncomingDocumentDSP',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/DocumentsDSP/Cards/IncomingDSPCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/outgoingdsp',
        name: 'OutgoingDocumentDSP',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/DocumentsDSP/Cards/OutgoingDSPCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/protocol',
        name: 'ProtocolDocument',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/Documents/ProtocolCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/meeting',
        name: 'Meeting',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
        component: () => import('@/views/EventsManagment/MeetingCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: "*",
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => import("@/views/Common/NotFound.vue")
    },
];

const router = new VueRouter({
    routes: routes.filter(route => route.meta.config.includes(process.env.VUE_APP_CONFIGURATION)),
});

const routeNameOpenTypes = {
    IncomingDocument: "Chancellery|Documents.Document",
    IncomingDocumentDSP: "Chancellery|Documents.Document",
    OutgoingDocument: "Chancellery|Documents.Document",
    OutgoingDocumentDSP: "Chancellery|Documents.Document",
    OutgoingDocumentCommon: "Chancellery|Documents.Document",
    InnerDocument: "Chancellery|Documents.Document",
    EuolStatement: "CitizenStatements.EuolStatement",
    IQalaStatement: "Chancellery|Documents.Document",
    Resolution: "Documents|Orders|CitizenStatements.Resolution",
    ActItem: "Documents|Orders|CitizenStatements.Resolution",
    Order: "Orders.Order",
    IQalaOrder: "Documents|Orders|CitizenStatements.Resolution",
    NPADocument: "Documents.NPA.Document",
    ProtocolDocument: "Documents.ProtocolDocument",
    Meeting: "None.Meeting",
    ReportQuery: "Documents.ReportGeneration.Query",
    ReportResolution: "Documents|Orders|CitizenStatements.Resolution",
    ReportInteraction: "Documents.ReportGeneration.Form"
};

const shouldUpdateSource = function(route) {
    return route.params?.fromSource !== true && routeNameOpenTypes[route.name] !== undefined;
};

router.beforeEach(async (to, from, next) => {

    if (Object.prototype.hasOwnProperty.call(to, 'meta') &&
        Object.prototype.hasOwnProperty.call(to.meta, 'deleteDocumentOpenStatus') &&
        to.meta.deleteDocumentOpenStatus === true)
    {
        await store.dispatch('actionsource/deleteDocumentOpenStatus');
    }

    if (from === VueRouter.START_LOCATION)
    {
        await store.dispatch('auth/init', to);
    }

    if (!store.state.auth.authorized)
    {
        if (to.matched.some((record) => record.meta.authRequired))
        {
            next('/login');
        }
        else
            next();
    }
    else if (to.name === 'Login')
    {
        next(store.getters['header/getFirstChapterRoute']);
    }
    else
    {
        if (to.matched.every(route => route.path == '*')) {
            next(store.getters['header/getFirstChapterRoute']);
        }
        else {
            next();

            if (shouldUpdateSource(to)) {
                if (to.name === "ReportInteraction")
                    await store.dispatch('actionsource/loadDataSource',
                        {
                            id: "ShowReportForm",
                            openType: "Documents.ReportGeneration.Form",
                            params: { queryId: to.query?.queryId, executionId: to.query?.executionId }
                        }, { root: true });
                else
                    await store.dispatch('actionsource/loadDataSource',
                        { 
                            id: to.query?.id,
                            openType: routeNameOpenTypes[to.name],
                            common: to.query?.common && store.getters['auth/isCommonServiceEnabled']
                        }, { root: true });
            }
        }
    }

});

export default router;
