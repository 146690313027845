import Vue from 'vue';
import sys from '@/services/system';

const tableGenerator = {
    namespaced: true,
    state: {
        isPending: false,
        isVisible: false,

        resolve: null,
        reject: null,

        dataSource: null,
        key: ""
    },
    mutations: {
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_IS_VISIBLE(state, payload) {
            state.isVisible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },  
        SET_DATASOURCE(state, payload) {
            state.dataSource = payload;            
        },
        SET_KEY(state, payload) {
          state.key = payload;  
        },
        UPDATE_DATASOURCE(state, payload) {                 
            if (!this._vm.$_.has(state.dataSource, payload.property)) 
                Vue.set(state.dataSource, payload.property, payload.value)
            else
                this._vm.$_.set(state.dataSource, payload.property, payload.value)
        },
    },
    actions: {
        async open({ commit }, jsonschema) {

            let dataSource = JSON.parse(jsonschema);
            
            commit('SET_KEY', sys.generateUUID());
            commit('SET_DATASOURCE', dataSource);
            commit('SET_IS_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state/*, rootGetters*/ }) {
            commit('SET_IS_VISIBLE', false);
            
            let result = {
                DocumentId: "", //rootGetters['actionsource/getDataSource'].Data.Object.Document.id,     
                data: []                      
            };

            state.dataSource.sections.forEach(section => {
                let items = section.cols;
                let dataCols = items.filter(i => !Array.isArray(i.items) || i.items.length == 0);
                let hasNextLevel = true;
    
                while(hasNextLevel) {
                    items = items.reduce((acc, item) => {
                            item.items?.forEach(i => acc.push(i));
                        return acc;
                    }, []);
    
                    hasNextLevel = items.length > 0;
                    
                    items.filter(i => !Array.isArray(i.items) || i.items.length == 0).forEach(i => dataCols.push(i));
                } 
                
                let data = section.rows.reduce((a, r) => {

                    if (r.readonly)
                        return a;

                        
                    console.log(r.data);
                    let dataRow = r.data.reduce((ra, rd, ri) => {

                        if (dataCols[ri].type)
                            ra.push(rd);

                        return ra;
                    }, []);

                    if (dataRow.length > 0)
                        a.push(dataRow);

                    return a;

                }, []);

                result.data.push(data);

            });

            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_IS_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        updateDataSource({ commit }, data) {
            commit('UPDATE_DATASOURCE', data);
        },
    },
    getters: {
        isPending: (s) => s.isPending,
        isVisible: (s) => s.isVisible,        
        isValid: () => true,
        dataSource: (s) => s.dataSource,
        skey: (s) => s.key,
    }
}

export default tableGenerator;