import { httpAPI } from "@/api/httpAPI";

const addProtocolLink = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        resolve: null,
        reject: null,

        protocolSearch: null,
        selectedProtocol: null,
        documentPage: 1,
        documentPerPage: 6,
        protocols: [],
        documentsTotal: 0,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_PROTOCOLS(state, payload) {
            state.protocols = payload;
        },
        SET_SELECTED_PROTOCOL(state, payload) {
            state.selectedProtocol = payload;
        },
        SET_PROTOCOL_SEARCH(state, payload) {
            state.protocolSearch = payload;
        },
        SET_DOCUMENT_PAGE(state, payload) {
            state.documentPage = payload;
        },
        SET_DOCUMENT_TOTAL(state, payload) {
            state.documentsTotal = payload;
        },
    },
    actions: {
        async open({ dispatch, commit }) {
            
            commit('SET_VISIBLE', true);
            
            commit('SET_DOCUMENT_PAGE', 1);
            commit('SET_PROTOCOL_SEARCH', null);
            commit('SET_SELECTED_PROTOCOL', null);
            
            await dispatch('fetchProtocols');

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ protocol: state.selectedProtocol });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        async changeSearch({ commit, dispatch }, value) {
            commit('SET_PROTOCOL_SEARCH', value);
            commit('SET_DOCUMENT_PAGE', 1);
            await dispatch('fetchProtocols');
        },
        async changePage({ commit, dispatch }, value) {
            commit('SET_DOCUMENT_PAGE', value);
            await dispatch('fetchProtocols');
        },
        async fetchProtocols({ commit, state }) {
            commit('SET_LOADING', true);

            let filter = 
            {
                SearchStr: state.protocolSearch,
                ControlDocumentState: -1,
                DocType: "Avrora.Objects.Modules.Docflow.DocflowObjects.ProtocolDocument",
                DocumentTypeC4: "2",
                OrderBy: 3,
                OrderDir: true,
                Limit: state.documentPerPage,
                Offset: (state.documentPage - 1) * state.documentPerPage
            };

            let response = await httpAPI({
                //url: `/api/correspondence/documentselect?filter=${JSON.stringify(filter)}`,
                url: `api/correspondence/list?collection=Protocols&filter=${JSON.stringify(filter)}`,
                method: 'GET',
            });

            if (response) {
                commit('SET_PROTOCOLS', response.data.payload.data ?? []);
                commit('SET_DOCUMENT_TOTAL', response.data.payload.total);
            }
            else {
                commit('SET_DOCUMENTS', []);
                commit('SET_DOCUMENT_TOTAL', 0);
            }
            commit('SET_LOADING', false);
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getProtocols: (s) => s.protocols,
        getSelectedProtocol: (s) => s.selectedProtocol,
        getProtocolSearch: (s) => s.protocolSearch,
        getDocumentPage: (s) => s.documentPage,
        getDocumentPerPage: (s) => s.documentPerPage,
        getDocumentsTotal: (s) => s.documentsTotal,
    }
}

export default addProtocolLink;