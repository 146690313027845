import selectMembersNew from './store/select-members-new';
import pdfView from "./store/pdf-view";
import beginAdjustment from "./store/begin-adjustment";
import createNewVersion from  "./store/create-new-version";
import reject from "./store/reject";
import setAdjustment from "./store/set-adjustment";
import setSign from "./store/set-sign";
import orderCounter from "./store/order-counter";
import executersGroup from "./store/executers-group";
import startProcess from "./store/start-process";
import addDocumentLink from "./store/add-document-link";
import cscRegistry from "./store/CSCRegistry";
import cscAnswer from "./store/CSCAnswer";
import cscFormAnAnswer from "./store/CSCFormAnAnswer";
import cscFormAnAnswer0103X017 from "./store/CSCFormAnAnswer0103X017";
import cscFormAnAnswer060X0001 from "./store/CSCFormAnAnswer060X0001";
import cscRejection from "./store/CSCRejection";
import cscInvoice from "./store/CSCInvoice";
import cscComment from "./store/CSCComment";
import cscContractInfo from "./store/CSCContractInfo";
import selectNomenclature from "./store/select-nomenclature";
import attachDuplicates from "./store/attach-duplicate";
import setDocumentControl from "./store/set-document-control";
import changeControl from "./store/change-control";
import prolongateControl from "./store/prolongate-control";
import changeEuolStatementUniqueNumber from "./store/change-euol-statement-unique-number";
import prolongate from "./store/prolongate";
import printFishka from "./store/print-fishka";
import registerSection from "./store/register-section";
import registerDocument from "./store/register";
import executerAnswer from "./store/executer-answer";
import executerAnswerView from "./store/executer-answer-view";
import registerEuolStatementPrimary from "./store/register-euol-statement-primary";
import registerEuolStatementAs from "./store/register-euol-statement-as";
import euolAddExecution from "./store/euol-add-execution";
import govermentRegistration from "./store/goverment-registration";
import userReplacePassword from "./store/user-replace-password";
import notifyInactive from "./store/notify-inactive";
import importCertificate from "./store/import-certificate";
import certificateExpires from "./store/certificate-expires";
import notifySignErrorConfirm from "./store/notify-sign-error-confirm";
import userAgreement from "./store/user-agreement";
import nextPeriodDate from "./store/set_next_period_date";
import selectEnterprise from "./store/select-enterprise";
import sendForExecution from "./store/send-for-execution";
import addMeetingQuestion from "./store/add-meeting-question";
import formGenerator from "./store/form-generator";
import tableGenerator from "./store/table-generator";
import sendreportonexecution from "./store/sendreportonexecution";
import returnreason from "./store/returnreason";
import addProtocolLink from "./store/add-protocol-link";

const dialogs = {
    namespaced: true,
    state: {

    },
    actions: {

    },
    modules: {
        selectMembersNew,
        pdfView,
        beginAdjustment,
        createNewVersion,
        reject,
        setAdjustment,
        setSign,
        orderCounter,
        executersGroup,
        startProcess,
        addDocumentLink,
        cscRegistry,
        cscAnswer,
        cscFormAnAnswer,
        cscFormAnAnswer0103X017,
        cscFormAnAnswer060X0001,
        cscRejection,
        cscInvoice,
        cscComment,
        cscContractInfo,
        selectNomenclature,
        attachDuplicates,
        setDocumentControl,
        changeControl,
        prolongateControl,
        changeEuolStatementUniqueNumber,
        prolongate,
        printFishka,
        registerSection,
        registerDocument,
        executerAnswer,
        executerAnswerView,
        registerEuolStatementPrimary,
        registerEuolStatementAs,
        euolAddExecution,
        govermentRegistration,
        userReplacePassword,
        notifyInactive,
        importCertificate,
        certificateExpires,
        notifySignErrorConfirm,
        userAgreement,
        nextPeriodDate,
        selectEnterprise,
        sendForExecution,
        addMeetingQuestion,
        formGenerator,
        tableGenerator,
        sendreportonexecution,
        returnreason,
        addProtocolLink
    }
}

export default dialogs;