import Axios from 'axios';
//import { httpAPI } from "@/api/httpAPI";
import Vue from 'vue';

function columnSumm(dataSource, sectionKey, name, tdKey) {
    return dataSource.sections[sectionKey][name].data.reduce((a, v) => {
        if (v.readonly)
            return a;

        let value = parseInt(v.values[tdKey]);

        if (!isNaN(value))                            
            a += value;                            

        return a;
    }, 0);
}

const reportinteraction = {
    namespaced: true,    
    state: {
        isActive: false,
        isPending: null,
        cancellationTokenSorce: null,
        dataSource: null,
        updateKey: 0
    },    
    mutations: {
        SET_IS_ACTIVE(state, payload) {
            state.isActive = payload;
        },
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_CANCELLATION_TOKEN_SOURCE(state, payload) {
            state.cancellationTokenSorce = payload;
        },
        SET_DATASOURCE(state, payload) {
            state.dataSource = payload;
        },        
        UPDATE_DATASOURCE(state, payload) {
            if (!this._vm.$_.has(state.dataSource, payload.property)) 
                Vue.set(state.dataSource, payload.property, payload.value)
            else
                this._vm.$_.set(state.dataSource, payload.property, payload.value)
        },
        SET_UPDATEKEY(state, payload){
            state.updateKey = payload;
        }
    },
    actions: {  
        setDataSource({commit}, dataSource) {
            commit({ type: 'SET_DATASOURCE', dataSource });
        },
        /**
         * Загруза данных для генерирования формы (вкладки?) отчета
         * @param {*} id - идентификатор запроса на формирование, чтобы загрузить данные с сервера
         * @param {*} description - объект, который содержит поля rawScheme (строка-схема отчета) и rawData (строка-данные для отчета)
         */
        // eslint-disable-next-line no-unused-vars
        async loadDataSource({ state, commit}, { id, description }) {
            commit('SET_IS_PENDING', true);

            if (state.cancellationTokenSorce)
                state.cancellationTokenSorce.cancel('New request started');

            commit('SET_CANCELLATION_TOKEN_SOURCE', Axios.CancelToken.source());
            
            let dataSource = null;
            let data = null;

            if (description) {
                dataSource = description.rawScheme ? JSON.parse(description.rawScheme) : null;
                data = description.rawData ? JSON.parse(description.rawData) : null;
            }
            /* else {
                //load some data here
            } */

            dataSource.sections.forEach((s, si) => {
                // eslint-disable-next-line no-unused-vars
                Object.entries(s).forEach(([key, item]) => {                      
                    if (item.type && item.type != 'label') { //fields
                        item.value = data && Object.prototype.hasOwnProperty.call(data, key) ? data[key] : item.default;
                    }
                    else if (item.data && item.header) { //tables

                        let items = item.header;
                        let dataCols = items.filter(i => !Array.isArray(i.items) || i.items.length == 0);
                        let hasNextLevel = true;
            
                        while(hasNextLevel) {
                            items = items.reduce((acc, item) => {
                                    item.items?.forEach(i => acc.push(i));
                                return acc;
                            }, []);
            
                            hasNextLevel = items.length > 0;
                            
                            items.filter(i => !Array.isArray(i.items) || i.items.length == 0).forEach(i => dataCols.push(i));
                        }

                        // eslint-disable-next-line no-unused-vars
                        item.data.forEach((d, di) => {
                            if (d.readonly && d.values.some(v => typeof v === "string" && v.startsWith("this."))) {
                                d.calculated = Array(d.values.length).fill().map(() => null);                                    
                                d.values.forEach((v, vi) => {
                                    d.calculated[vi] = columnSumm(dataSource, si, key, vi);
                                })
                            }
                            
                            if (data && Object.prototype.hasOwnProperty.call(data, key)) {
                                // eslint-disable-next-line no-unused-vars
                                d.values.forEach((v, vi) => {
                                    if (dataCols[vi].type && !v?.startsWith('this.'))
                                        d.values[vi] = data[key][di][vi];
                                });
                            }                            
                        });

                        item.data.forEach((d) => {
                            if (d.readonly && d.values.some(v => typeof v === "string" && v.startsWith("this."))) {
                                d.values.forEach((v, vi) => {
                                    d.calculated[vi] = columnSumm(dataSource, si, key, vi);
                                });
                            }
                        });
                    }
                });                    
            });             

            commit('SET_IS_PENDING', false);

            if (dataSource)
                commit('SET_DATASOURCE', dataSource);
            else
                commit('SET_DATASOURCE', []);
        }, 
        setIsActive({commit}, isActive) {
            commit('SET_IS_ACTIVE', isActive);
        },
        updateDataSource({ commit }, data) {
            commit('UPDATE_DATASOURCE', data);
        },
        calculate({ state, commit }, data) {

            let hasCalulation = false;
            let fIdxs = [];
            
            state.dataSource.sections[data.sectionKey][data.name].data.forEach((d, i) => {
                if (d.readonly && typeof d.values[data.tdKey] === 'string' && d.values[data.tdKey].startsWith('this.'))
                    fIdxs.push(i);
            });

            if (fIdxs.length > 0) {
                fIdxs.forEach(i => {
                    switch(state.dataSource.sections[data.sectionKey][data.name].data[i].values[data.tdKey])
                    {
                        case "this.columnSumm": {
                            let summ = columnSumm(state.dataSource, data.sectionKey, data.name, data.tdKey);

                            commit('UPDATE_DATASOURCE', { property: `sections[${data.sectionKey}].${data.name}.data[${i}].calculated[${data.tdKey}]`, value: summ });
                            
                            if (!hasCalulation)
                                hasCalulation = true;
                        }
                    }
                })
            }

            if (hasCalulation)
                commit('SET_UPDATEKEY', this._vm.$moment.utc().valueOf());
        },
        fetchCurrentFillData({ state }) {
            if (!state.dataSource)
                return null;

            let result = {};

            // eslint-disable-next-line no-unused-vars
            state.dataSource.sections.forEach((s, si) => {
                
                    Object.entries(s)
                    // eslint-disable-next-line no-unused-vars
                    .filter(([key, item]) => {                        
                        return key != 'title' && key != 'description';
                    })
                    // eslint-disable-next-line no-unused-vars
                    .forEach(([key, item]) => {
                        if (Object.prototype.hasOwnProperty.call(item, 'value')) {
                            if (item.visible)
                                result[key] = item.value;
                        }
                        else if (Object.prototype.hasOwnProperty.call(item, 'data') && Object.prototype.hasOwnProperty.call(item, 'header'))
                        {
                            let items = item.header;
                            let dataCols = items.filter(i => !Array.isArray(i.items) || i.items.length == 0);
                            let hasNextLevel = true;
                
                            while(hasNextLevel) {
                                items = items.reduce((acc, item) => {
                                        item.items?.forEach(i => acc.push(i));
                                    return acc;
                                }, []);
                
                                hasNextLevel = items.length > 0;
                                
                                items.filter(i => !Array.isArray(i.items) || i.items.length == 0).forEach(i => dataCols.push(i));
                            }

                            let data = item.data.reduce((a, r) => {
                                let dataRow = r.values.reduce((ra, rd, ri) => {
            
                                    if (dataCols[ri].type && !r.readonly)
                                        ra.push(rd);
                                    else
                                        ra.push(null);
            
                                    return ra;
                                }, []);
            
                                if (dataRow.length > 0)
                                    a.push(dataRow);
            
                                return a;
            
                            }, []);

                            result[key] = data;
                        }
                    });
            });

            return result;
        }
    },
    getters: {
        isActive: s => s.isActive,
        isPending: s => s.isPendig,
        getDataSource: s => s.dataSource,
        getUpdateKey: s => s.updateKey  
    },
};

export default reportinteraction;
