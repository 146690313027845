// import sys from '@/services/system';
// import { httpAPI } from "@/api/httpAPI";

import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

import { SET_BACKEND_CONNECTION_STATE } from '../store/mutation-types'

export default {
    install(Vue, store) 
    {
        
        let url = process.env.NODE_ENV === "production" 
            ? '/users-hub'
            : 'http://localhost:7000/users-hub';
        
        const connection = new HubConnectionBuilder()
        .withUrl(url)
        .configureLogging(LogLevel.Information)
        .build();

        const usersHub = new Vue();
        
        usersHub.GetConnectionId = () => {
            return connection.connectionId;
        }

        //#region События, которые инициирует клиент

        usersHub.CheckToken = (token) => {
            return connection.invoke('CheckToken', token);
        }

        usersHub.DiscoverRegion = () => {
            return connection.invoke('DiscoverUserRegion');
        }

        //#endregion

        //#region События, на которые подписан клиент

        connection.on('InactiveDetected', (message) => {
            usersHub.$emit('on-inactive-detected', { invokedAt: message })
        });

        connection.on('UserLogout', (message) => {
            usersHub.$emit('on-user-logout', { invokedAt: message })
        });

        connection.on('AbortInactiveDetected', (message) => {
            usersHub.$emit('on-abort-inactive-detected', { invokedAt: message })
        });

        //#endregion
        
        Vue.prototype.$usersHub = usersHub;

        //#region Запуск SignalR

        let startedPromise = null;

        function start() {
            if (startedPromise != null && store.getters.getBackendConnectionState != connection.state) {
                store.commit(SET_BACKEND_CONNECTION_STATE, connection.state);                
            }
            
            startedPromise = connection
                .start()
                .then(() =>
                {
                    var sessionId = sessionStorage.getItem('SessionId');

                    if (!sessionId)
                        console.error('SessionId not exist');

                    store.commit(SET_BACKEND_CONNECTION_STATE, connection.state);

                    let token = localStorage.getItem('Token')

                    if (token) {
                        connection.invoke('UpdateConnectionBinding', token, sessionId)
                            .then(data => console.log(data))
                            .catch(err => console.error(err));
                    }

                    connection.invoke('DiscoverUserRegion')
                            .then(data => store.commit("SET_REGION", data));
                })
                .catch(() =>
                {
                    return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
                });

            return startedPromise;
        }

        connection.onclose(() => start());

        start();

        //#endregion
    }
}