const selectMembersChip = {
    namespaced: true,
    state: {
        params: null,
        items: [],
        loading: true,
        autoFocus: false
    },
    mutations: {
        SET_PARAMS(state, payload) {
            state.params = payload;
        },
        SET_ITEMS(state, payload) {
            state.items = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_AUTO_FOCUS(state, payload) {
            state.autoFocus = payload;
        },
    },
    actions: {
        //вызываем при выпадении меню
        async updateItems({ commit, dispatch }, { params }) {
            commit('SET_PARAMS', params);
            commit('SET_LOADING', true);
            let rawItems = null;
            let tmp = [];

            if (params.only_work_enterprises) {
                rawItems = await dispatch('references/getEnterprises', { forDepartment: false }, { root: true });

                if (rawItems) {
                    tmp = rawItems;
                }
            }
            else {
                if (params.NPASignersAct)
                    rawItems = await dispatch('references/getDialogExecutersForNPA', { params: 'SignersAct' }, { root: true });
                else if (params.NPAAdjasments)
                    rawItems = await dispatch('references/getDialogExecutersForNPA', { params: 'Adjasments' }, { root: true });
                else 
                    rawItems = await dispatch('references/getNewDialogExecuters',
                    { 
                        params: {
                            include_inner: params.includeInner,
                            include_outer: params.includeOuter,
                            include_esedo: params.includeOuterEnterprises,
                            include_jur: params.includeJuridical,
                            include_fiz: params.includePersons,
                            include_work_enterprises: !params.includeOuter,
                            ignore_cache: false,
                            signersonly: params.onlySigners,
                            signers_boundaries: params.onlySigners === true && params.includeOuter === true
                                                ? Object.prototype.hasOwnProperty.call(params, "signersBoundaries") && Array.isArray(params.signersBoundaries)
                                                    ? params.signersBoundaries.join()
                                                    : null
                                                : null
                        }
                    }, { root: true });

                if (params.onlySigners === true) {
                    if (rawItems.Signers && Array.isArray(rawItems.Signers.values) && rawItems.Signers.values.length > 0) {
                        tmp = tmp.concat(rawItems.Signers.values);
                    }
    
                    if (rawItems.OuterSigners && Array.isArray(rawItems.OuterSigners.values) && rawItems.OuterSigners.values.length > 0) {
                        tmp = tmp.concat(rawItems.OuterSigners.values);
                    }
                }
                else {
                    if (rawItems.InnerExecuters && Array.isArray(rawItems.InnerExecuters.values) && rawItems.InnerExecuters.values.length > 0) {
                        tmp = tmp.concat(rawItems.InnerExecuters.values);
                    }
    
                    if (rawItems.OuterExecuters && Array.isArray(rawItems.OuterExecuters.values) && rawItems.OuterExecuters.values.length > 0) {
                        tmp = tmp.concat(rawItems.OuterExecuters.values);
                    }
                }
                
                if (rawItems.OuterESEDO && Array.isArray(rawItems.OuterESEDO.values) && rawItems.OuterESEDO.values.length > 0)
                    tmp = tmp.concat(rawItems.OuterESEDO.values);

                if (rawItems.OuterJur && Array.isArray(rawItems.OuterJur.values) && rawItems.OuterJur.values.length > 0)
                    tmp = tmp.concat(rawItems.OuterJur.values);
            
                if (rawItems.OuterFiz && Array.isArray(rawItems.OuterFiz.values) && rawItems.OuterFiz.values.length > 0)
                    tmp = tmp.concat(rawItems.OuterFiz.values);
            }

            commit('SET_ITEMS', tmp);
            commit('SET_LOADING', false);
        }
    },
    getters: {
        getParams: (s) => s.params,
        isLoading: (s) => s.loading,
        getItems(state, getters, rootState, rootGetters) {

            const isHierarchicallyAllowed = function(item) {
                if (item.element_category !== "InnerExecuter")
                    return true;
    
                let currentUserPosition = rootGetters['auth/getUserPositionFlag'];
                /*
                -1: Рядовой сотрудник
                 0: Руководитeль организации
                 1: Заместитель руководителя организации
                 2: Руководитель подразделения
                 3: Заместитель руководителя подразделения
                 4: Канцеляр
                 5: Особые полномочия
                */
                switch (currentUserPosition)
                {
                    case 5:
                        return [100, 2, 3, 5].includes(item.position);
    
                    case 100:
                    case 2:
                    case 3:
                        return [100, 2, 3].includes(item.position);
    
                    case 0:
                    case 1:
                    case 4:
                        return true;
    
                    default:
                        return false;
                }
            };
            
            const filterFunc = function(item, params) {
                let hierarchical = params.hierarchical === true;

                if (hierarchical && !isHierarchicallyAllowed(item))
                    return false;
                
                let selected = params.selected ?? [];
                let implicitExclude = params.implicitExclude ?? [];
                return (item.allow || params.only_work_enterprises) && !selected.includes(item.id) && !implicitExclude.includes(item.id);
            };

            return state.items?.filter(x => filterFunc(x, state.params));
        },
        isNeedToSetFocus: (s) => s.autoFocus,
    }
};

export default selectMembersChip;