import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import router from '@/router';
import { resetStoreState } from '@/store';
import i18n from '@/i18n';

/**
 * Модуль auth
 */
const auth = {
    namespaced: true,
    state: {
        authorized: false,
        authPending: false,
        userInfo: null,
        errorMessage: '',
        cancellationTokenSorce: null
    },
    mutations: {
        SET_AUTH_PENDING(state, payload) {
            state.authPending = payload.pending;
        },
        SET_AUTHORIZED(state, payload) {
            state.authorized = payload.authorized;
        },
        SET_ERROR_MESSAGE(state, payload) {
            state.errorMessage = payload.errorMessage;
        },
        SET_USER_INFO(state, payload) {
            state.userInfo = payload.userInfo;
            httpAPI.defaults.headers.common['token'] = payload.userInfo?.token;
            httpAPI.defaults.headers.common['sessionId'] =  sessionStorage.getItem('SessionId');
        },
        SET_USER_INFO_POSITION(state, payload) {
            state.userInfo.Position = payload.position;
        },
    },
    actions: {
        async init({ state, commit, dispatch, rootGetters }, currentRoute) {

            if (state.authorized) return;

            let token = localStorage.getItem('Token');

            if (token) {
                commit({ type: 'SET_AUTH_PENDING', pending: true });

                while (typeof this._vm.$usersHub === 'undefined')
                    await new Promise(resolve => setTimeout(resolve, 100));

                while (rootGetters['getBackendConnectionState'] !== "Connected")
                    await new Promise(resolve => setTimeout(resolve, 100));

                let checkResultString = await this._vm.$usersHub.CheckToken(token);

                let checkResult = JSON.parse(checkResultString);
                
                if (checkResult.founded) {
                    console.log("Токен_найден.");
                    dispatch('authorizeByUserInfo', { userInfo: JSON.parse(checkResult.state), currentRoute });
                }
                else {
                    console.log("Токен_отсутствует");
                    localStorage.removeItem('Token');
                }

                commit({ type: 'SET_AUTH_PENDING', pending: false });
            }
        },
        async fetchAuthorize({ commit, dispatch, rootGetters }, { login, password, sign, signType, salt }) {
            let connectionState = rootGetters['getBackendConnectionState'];
            let userRegion = rootGetters['getUserRegion'];

            resetStoreState();
            
            commit('SET_BACKEND_CONNECTION_STATE', connectionState, { root: true });
            commit("SET_REGION", parseInt(userRegion), { root: true });

            this._vm.$notify.closeToasts();
            commit({ type: 'SET_AUTH_PENDING', pending: true });
            commit({ type: 'SET_ERROR_MESSAGE', errorMessage: '' });

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: '/api/auth/login',
                method: 'POST',
                data: JSON.stringify({ Username: login, Password: password, Signature: sign, Language: i18n.locale, SignatureType: signType, AuthSalt: salt }),
                headers: { 
                        'Content-Type': 'application/json', 
                        'connectionId': this._vm.$usersHub.GetConnectionId()
                    },
                cancelToken: this.cancellationTokenSorce.token,
            });

            commit({ type: 'SET_AUTH_PENDING', pending: false });
            
            if (response && response.status == 200) {
                if (response.data.success) {

                    commit({ type: 'SET_AUTHORIZED', authorized: true });
                    commit({ type: 'SET_USER_INFO', userInfo: response.data.payload });
                    commit({ type: 'SET_ERROR_MESSAGE', errorMessage: '' });

                    if (response.data.payload.needChangePassword) {
                        try {
                            let rez = await dispatch('dialogs/userReplacePassword/open', { showmessage: true, oldpass: password }, { root: true });
                            if (rez)
                                this._vm.$notify.success(rez);
                        }
                        catch(ex)
                        {
                            await dispatch('fetchLogout');
                            commit({ type: 'SET_ERROR_MESSAGE', errorMessage: i18n.t('Процедура_смены_пароля_прервана') }); 
                            return;   
                        }
                    }

                    
                    if (!response.data.payload.consent && process.env.VUE_APP_CONFIGURATION == 'C4') {
                        try {
                            let rez = await dispatch('dialogs/userAgreement/open', { viewonly: false }, { root: true });
                            if (rez)
                                this._vm.$notify.success(rez);
                        }
                        catch(ex)
                        {
                            await dispatch('fetchLogout');
                            //commit({ type: 'SET_ERROR_MESSAGE', errorMessage: i18n.t('Не_подтверждено_согласие_с_Соглашением') }); 
                            return;
                        }
                    }
                    
                    if (response.data.payload.permissions.includes("NPA")){
                        if (response.data.payload.permissions.includes("AllNPAView")){
                            commit('npa/SET_CATEGORY', "-1", { root: true });
                        }
                        else {
                            commit('npa/SET_CATEGORY', "0", { root: true });
                        }
                    }

                    let chapters = rootGetters['header/getMyChapters'];

                    if (chapters.length > 0) {
                        await router.push(chapters[0].route);
                        localStorage.setItem('Token', response.data.payload.token);

                        if (response.data.payload.certificateEndDate) {
                            let certEndDate = this._vm.$moment(response.data.payload.certificateEndDate);
                            let certDaysRemain = certEndDate.diff(this._vm.$moment(), 'days');
                            
                            if (certDaysRemain > 0 && certDaysRemain <= 31)
                                await dispatch('dialogs/certificateExpires/open', { certEndDate: certEndDate.format('DD.MM.YYYY'), certDaysRemain }, { root: true });
                        }
                    }
                    else {
                        await dispatch('fetchLogout');
                        commit({ type: 'SET_ERROR_MESSAGE', errorMessage: "Отсутствуют права доступа.\nПожалуйста, обратитесь к администратору системы." });
                    }
                }
                else
                    commit({ type: 'SET_ERROR_MESSAGE', errorMessage: response.data.message });
            }
        },
        async authorizeByUserInfo({ commit, dispatch, rootGetters }, { userInfo, currentRoute }) {

            dispatch('setOverlayVisible', { visible: true }, { root: true });

            commit({ type: 'SET_AUTHORIZED', authorized: true });
            commit({ type: 'SET_USER_INFO', userInfo });
            commit({ type: 'SET_ERROR_MESSAGE', errorMessage: '' });

            if (!currentRoute) {
                let chapters = rootGetters['header/getMyChapters'];

                if (chapters.length > 0) {
                    await router.push(chapters[0].route);
                }
                else {
                    await dispatch('fetchLogout');
                    commit({ type: 'SET_ERROR_MESSAGE', errorMessage: "Отсутствуют права доступа.\nПожалуйста, обратитесь к администратору системы." });
                }
            }
            
            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async fetchLogout({ commit, dispatch }) {            
            this._vm.$notify.closeToasts();
            dispatch('setOverlayVisible', { visible: true, text: `Выход из системы...` }, { root: true });
            
            let response = await httpAPI({
                url: '/api/auth/logout',
                method: 'GET'
            });

            if (response && response.status == 200) {
                commit({ type: 'SET_AUTHORIZED', authorized: false });
                commit({ type: 'SET_ERROR_MESSAGE', errorMessage: '' });
                
                localStorage.removeItem('Token');

                if (router.currentRoute.name !== 'Login')
                    await router.replace({ name: 'Login' });

                commit({ type: 'SET_USER_INFO', userInfo: null });
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async fetchEmergencyLogout({ commit }) {
            this._vm.$notify.closeToasts();

            localStorage.removeItem('Token');

            commit({ type: 'SET_AUTHORIZED', authorized: false });
            commit({ type: 'SET_ERROR_MESSAGE', errorMessage: '' });

            if (router.currentRoute.name !== 'Login')
                await router.replace({ name: 'Login' });

            commit({ type: 'SET_USER_INFO', userInfo: null });
            
        }
    },
    getters: {
        isPendingData: (s) => s.authPending,
        isAuthorized: (s) => s.authorized,
        getErrorMessage: (s) => s.errorMessage,
        getUserInfo: (s) => s.userInfo,
        getUserEnterpriseId: (s) => s.userInfo?.enterpriseId ?? null,
        isCommonServiceEnabled: (s) => s.userInfo?.permissions?.includes('CommonDocumentService') ?? false,
        getUserWorkplaceId: (s) => s.userInfo?.workplaceId,
        getUserPositionFlag: (s) => s.userInfo?.position ?? 100,
        getUserEDSNUCStatus: (s) => s.hasNUC ?? false
    },
};

export default auth;
