import Axios from 'axios';
import i18n from '@/i18n'
import { httpAPI } from "@/api/httpAPI";
import sys from '@/services/system';
import _ from 'lodash'

/**
 * Модуль attachments
 */
const attachments = {
    namespaced: true,    
    state: {
        updateCancelTokenSource: null,
        dataSource: [],
        currentDocumentId: null,
        loadingAttachments: {},
        previewSrc: ""
    },
    mutations: {
        ADD_LOADIGN_ATTACHMENT(state, payload) {
            
            if (!state.loadingAttachments[payload.id])
                state.loadingAttachments[payload.id] = [];

            let data = state.loadingAttachments[payload.id];
            
            if (!data.find(i => i.Id == payload.attachment.Id))
                data.push(payload.attachment);
        },
        DEL_LOADIGN_ATTACHMENT(state, payload) {
            
            if (!state.loadingAttachments[payload.id])
                return;

            var data = state.loadingAttachments[payload.id]
            var item = data.find(i => i.id == payload.attachment.id);

            if (item)
            {
                data.splice(data.indexOf(item), 1);

                if (data.length == 0)
                    delete state.loadingAttachments[payload.id];
            }
        },
        SET_CURRENT_DOCUMENT_ID(state, payload) {
            state.currentDocumentId = payload.id;
        },
        SET_DATASOURCE(state, payload) {
            state.dataSource = payload.dataSource;
        },
        PUSH_DATASOURCE(state, payload) {
            if (!state.dataSource.find(i => i.id == payload.attachment.Id))
                state.dataSource.push(payload.attachment);
        },
        SET_UPDATE_CANCEL_TOKENSOURCE(state, payload) {
            state.updateCancelTokenSource = payload.updateCancelTokenSource;
        },
        SET_PREVIEW_SRC(state, payload) {
            state.previewSrc = payload.previewSrc;
        },
        UPDATE_lOADING_ATTACHMENT(state, payload)
        {
            this._vm.$_.set(state.loadingAttachments, payload.property, payload.value)
        }
    },
    actions: {  
        setPreviewSrc({ commit }, previewSrc) {
            commit({ type: 'SET_PREVIEW_SRC', previewSrc });
        },
        addLoadingAttachment({ commit }, { id, attachment }) {
            commit({ type: 'ADD_LOADIGN_ATTACHMENT', id, attachment });
        },
        delLoadingAttachment({ commit }, { id, attachment }) {
            commit({ type: 'DEL_LOADIGN_ATTACHMENT', id, attachment });
        },
        async deleteAttchment({ state, commit, dispatch, getters, rootGetters }, attachment) {
            // если у файла есть что-то в этом поле, мы расцениваем его как не загруженный / вложенный и не спрашиваем подтверждения
            if (attachment.Message) {
                let tempDataSource = Array.from(state.dataSource).filter(it => it.Id != attachment.Id);
                await commit('SET_DATASOURCE', { dataSource: tempDataSource });                        
                await dispatch({ type: 'delLoadingAttachment', id: state.currentDocumentId, attachment });
                return;
            }
            
            let isMeeting = rootGetters['actionsource/getDataSourceFullType'] === 'Meeting:#Avrora.Objects.Modules.Meetings';

            let methodName = "deleteattachment";
            
            if (isMeeting)
                methodName = "deletemeetingattachment";

            this._vm.$notify.confirm(
                i18n.t('Вы_действительно_хотите_удалить_вложение_filename?.message', { filename: attachment.Name }),
                async () => {
                    attachment.Message = i18n.t("Удаление...");

                    let response = await httpAPI({
                        url: `/api/actions/${methodName}?id=${attachment.Id}`,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json; charset=utf-8', 'isCommon': rootGetters['actionsource/isDataSourceCommon'] },
                    });

                    if (response) {
                        let tempDataSource = Array.from(state.dataSource).filter(it => it.Id != attachment.Id);
                        
                        if (attachment.IsActive)
                            commit({ type: 'SET_PREVIEW_SRC', previewSrc: '' });

                        await commit('SET_DATASOURCE', { dataSource: tempDataSource });
                        await dispatch({ type: 'delLoadingAttachment', id: state.currentDocumentId, attachment });

                        if (isMeeting) {
                            await dispatch('actionsource/updateDataSource', { property: `Data.Object.AttachmentsSource`, value: tempDataSource }, { root: true });
                            return;
                        }

                        if (getters.getDocumentAttachmentsCount == 0) {
                            dispatch('actionsource/updateDocument', null, { root: true });
                        }
                        else {
                            let currEntityDataSource = rootGetters['actionsource/getDataSource'];
                            let index = _.findIndex(currEntityDataSource?.Data?.Object?.Pages, function(page) { return page.FormId === '0202010' });
                            let path = `Data.Object.Pages[${index}].Count`;
                            let count = _.get(currEntityDataSource, path);
                            dispatch('actionsource/updateDataSource', { property: path, value: count - 1 }, { root: true });
                        }
                    }
                }
            )
        },
        async onFilePicked({ state, commit, getters, dispatch, rootGetters }, {e}) {
            let isIqalaStetment = rootGetters['actionsource/getDataSourceFullType'] === 'IQalaStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects';
            let isOrder = rootGetters['actionsource/getDataSourceFullType'] === 'Order:#Avrora.Objects.Modules.Docflow.DocflowObjects';
            let isMeeting = rootGetters['actionsource/getDataSourceFullType'] === 'Meeting:#Avrora.Objects.Modules.Meetings';

            commit('toolbar/SET_ISLOCK', { isLock: true }, { root: true });

            var needDocumentReload = getters.getDocumentAttachmentsCount == 0;

            await Promise.all(Array.from(e.target.files).map( async (f) => {
                    
                let attachment = sys.prepareAttachment(f, state.dataSource, this._vm.$notify);

                if (isIqalaStetment)
                    attachment.Group = 5;
                
                await commit('attachments/ADD_LOADIGN_ATTACHMENT', { id: state.currentDocumentId, attachment: attachment }, { root: true })
                await commit('attachments/PUSH_DATASOURCE', { attachment }, { root : true });                

                if (attachment.Message)
                    return;

                let formData = new FormData();
                formData.append('file', f);

                try 
                {
                    let saveAttachmentType = "saveattachment";

                    if (isOrder)
                        saveAttachmentType = "saveorderattachment";
                    
                    if (isMeeting)
                        saveAttachmentType = "savemeetingattachment";

                    let response = await httpAPI({
                        url: `/api/actions/${saveAttachmentType}?id=${state.currentDocumentId}&fileName=${attachment.Name}&fileSize=${attachment.Length}`,
                        method: 'POST',
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data', 'isCommon': rootGetters['actionsource/isDataSourceCommon'] },
                        onUploadProgress: (progressEvent) => {
                            let attach = getters.getLoadingAttachmnets[state.currentDocumentId].find(i => i.Id == attachment.Id);
                            let index = getters.getLoadingAttachmnets[state.currentDocumentId].indexOf(attach);
                            let progress = `${Math.trunc((100/progressEvent.total)*progressEvent.loaded)}%`;
                            if (attachment.Progress != progress)
                                commit('UPDATE_lOADING_ATTACHMENT', {property: `${state.currentDocumentId}[${index}].Progress`, value: progress });                            
                        },
                        cancelToken: attachment.CancelTokenSource.token,
                        skipErrorHandler: true
                    });

                    if (response.data.success && response.data.payload.Result == "OK")
                    {
                        await commit('attachments/DEL_LOADIGN_ATTACHMENT', { id: state.currentDocumentId, attachment: attachment }, { root: true });
                        let tempDataSource = state.dataSource.map(x => x);
                        tempDataSource.splice(tempDataSource.indexOf(attachment), 1);
                        tempDataSource.push(response.data.payload.Data.Object);
                        await commit('attachments/SET_DATASOURCE', { dataSource: tempDataSource }, { root: true });
                        this._vm.$notify.success(i18n.t('Файл_filename_успешно_загружен.message', {filename: attachment.Name}));

                        if (isMeeting)
                            await dispatch('actionsource/updateDataSource', { property: `Data.Object.AttachmentsSource`, value: tempDataSource }, { root: true });
                    }
                    else
                    {
                        let dsAttachment = state.dataSource.find(x => x.Id == attachment.Id);

                        if (dsAttachment) {
                            dsAttachment.Message = response.data.success ? response.data.payload.Message : response.data.message;
                            delete dsAttachment.Progress;
                        }

                        await commit('attachments/SET_DATASOURCE', { dataSource: state.dataSource.map(x => x) }, { root: true });
                        this._vm.$notify.alert(i18n.t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: attachment.Name, reason: response.data.success ? response.data.payload.Message : response.data.message }));
                    }
                }
                catch (exception) 
                {
                    let dsAttachment = state.dataSource.find(x => x.Id == attachment.Id);

                    if (dsAttachment) {
                        dsAttachment.Message = Axios.isCancel(exception) ? i18n.t('Загрузка_отменена') : i18n.t('Ошибка_загрузки_файла');
                        delete dsAttachment.Progress;
                    }

                    attachment.CancelTokenSource.cancel();
                    await commit('attachments/SET_DATASOURCE', { dataSource: state.dataSource.map(x => x) }, { root: true });
                    this._vm.$notify.alert(i18n.t('Ошибка_загрузки_файла:_filename.message', { filename: attachment.Name }));
                }
            }));

            e.target.value = null;
            
            if (getters.getDocumentAttachmentsCount > 0 && !isMeeting) {
                if (needDocumentReload) {
                    dispatch('actionsource/updateDocument', null, { root: true });
                }
                else {
                    let currEntityDataSource = rootGetters['actionsource/getDataSource'];
                    let index = _.findIndex(currEntityDataSource?.Data?.Object?.Pages, function(page) { return page.FormId === '0202010' });
                    let path = `Data.Object.Pages[${index}].Count`;
                    dispatch('actionsource/updateDataSource', { property: path, value: getters.getDocumentAttachmentsCount }, { root: true });
                }
            }

            commit('toolbar/SET_ISLOCK', { isLock: false }, { root: true })
        },
        setCurrentDocumentId({ commit }, id) {
            commit({ type: 'SET_CURRENT_DOCUMENT_ID', id });
        },
        setDataSource({commit}, dataSource) {
            commit({ type: 'SET_DATASOURCE', dataSource });
        },
        async updateDataSource({ state, commit, rootGetters }) {

            commit({ type: 'SET_PREVIEW_SRC', previewSrc: '' });
            
            if (state.updateCancelTokenSource)
                state.updateCancelTokenSource.cancel('New request started');

            commit({ type: 'SET_UPDATE_CANCEL_TOKENSOURCE', updateCancelTokenSource: Axios.CancelToken.source() })

            let objType = rootGetters['actionsource/getDataSourceType'];

            let response = objType === 'Meeting'
                ? { data: { payload: rootGetters['actionsource/getDataSourceEntity']?.AttachmentsSource ?? [] } }
                : await httpAPI({
                        url: `api/tabs/${objType === 'Order' || objType === 'Resolution' ? 'resolution' : ''}attachments/${state.currentDocumentId}`,
                        method: 'GET',
                        headers: { 'isCommon': rootGetters['actionsource/isDataSourceCommon'] },
                        cancelToken: state.updateCancelTokenSource.token,
                    });
            
            if (response) {
                var loadingAttachments = state.loadingAttachments[state.currentDocumentId] ?? [];
                var data = response.data.payload;

                data.forEach(i => { i.IsActive = false; });

                if (loadingAttachments.length > 0)
                    data = data.concat(loadingAttachments);
                
                commit({type: 'SET_DATASOURCE', dataSource: data });
            }
            else
                commit({type: 'SET_DATASOURCE', dataSource: [] });
        },
        validateFile(_, file) {
            if (file.name.length > sys.MAX_FILE_NAME_CHAR_COUNT)
                return { success: false, message: i18n.t("Превышена_допустимая_длина_имени_файла_limit.message", { limit: sys.MAX_FILE_NAME_CHAR_COUNT }) };

            if (sys.FORBIDDEN_SEQUENCES.some(seq => file.name.includes(seq)))
                return { success: false, message: i18n.t("Недопустимое_имя_файла") };

            if (file.size > sys.MAX_FILE_SIZE)
                return { success: false, message: i18n.t("Превышен_лимит_размера_вложения_limit.message", { limit: '15 Мб' }) };

            var fileExtention = file.name.match(new RegExp('[^.]+$'))[0].toLowerCase();

            if (sys.FORBIDDEN_EXTENSIONS.includes(fileExtention))
                return { success: false, message: i18n.t("Недопустимое_расширение_файла") };

            return { success: true, message: null };
        }
    },
    getters: {
        getLoadingAttachmnets: s => s.loadingAttachments,
        getCurrentDocumentId: s => s.currentDocumentId,
        getDataSource: s => s.dataSource,
        getDocumentAttachmentsCount: s => {            
            return s.dataSource.reduce((acc, item) => {
                if (!!item.Message || !!item.Progress)
                    return acc;
                else
                    return ++acc;
                }, 0);                
        },
        getPreviewSrc: s => s.previewSrc,
        getForbiddenExtensions: () => sys.FORBIDDEN_EXTENSIONS,
        getMaxFileSize: () => sys.MAX_FILE_SIZE,
        getMaxFilesSize: () => sys.MAX_FILES_SIZE,
        getMaxFilesCount: () => sys.MAX_FILE_COUNT,
        getMaxFileNameCharCount: () => sys.MAX_FILE_NAME_CHAR_COUNT
    },
};

export default attachments;
