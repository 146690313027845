const sendreportonexecution = {
    namespaced: true,
    state: {
        visible: false,

        controlDate: null,
        executer: null,

        resolve: null,
        reject: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_EXECUTER(state, payload) {
            state.executer = payload;
        },
        SET_CONTROLDATE(state, payload) {
            state.controlDate = payload ? this._vm.$moment(payload) : null;
        },
    },
    actions: {
        async open({ commit }) {
            commit('SET_VISIBLE', true);
            commit('SET_CONTROLDATE', '');
            commit('SET_EXECUTER', null);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            let formData = new FormData();
            formData.append('file', state.reportform);

            commit('SET_VISIBLE', false);

            state.resolve({ executer: state.executer, controldate: (state.controlDate? state.controlDate.format('YYYY-MM-DD') : null) });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isValid: () => true,
        getExecuter: (s) => s.executer,
        getControlDate: (s) => s.controlDate ? s.controlDate.format('YYYY-MM-DD') : '',
    }
}

export default sendreportonexecution;